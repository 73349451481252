<template>
  <div>
    <div class="section section-pd bg-pr-trans">
      <div class="page-header">
        <div
          class="container shape-container d-flex align-items-center text-center-sm"
        >
          <div class="col px-0">
            <div class="row align-items-center justify-content-center">
              <div class="col-lg-5">
                <h1 class="text-dark display-1 f-bold text-head mb-3">
                  Frequently Asked Question
                </h1>
                <p class="f-18 text-dark mb-0">
                  Hello! We've put together some commonly asked questions to
                  give you more information about Imperium and the services we
                  offer.
                </p>
              </div>
              <div class="col-md-7">
                <div class="text-center">
                  <img
                    class="headerimg"
                    src="/assets/images/svgs/faqs.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sectionPad pb-2 mb-7">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <Accordion id="accordion-example-1" :content="faqs" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Accordion from "@/components/Faqs/Accordion";
export default {
  name: "Calculator",
  components: { Accordion },
  data() {
    return {
      calculation: "general",
      faqs: [
        {
          active: true,
          title: "1. What is Imperium?",
          details: `
          Imperium is a digital ecosystem that brings together the consumer, energy solution provider, technical support services and the Bank with a view to bridge the gap between demand and supply of renewable energy solution.

        `,
        },
        {
          active: false,
          title: "2. Who is Imperium for?",
          details: `
            The platform is for everyone especially:
            <ul>
              <li>Individuals (home owners and tenants)</li>
              <li>Pharmacies, Hospitals, laboratories etc</li>
              <li>Religious organizations (Churches and Mosques)</li>
              <li>MSMEs (barber saloon, fashion store etc.)</li>
              <li>Farmers (farms, water pumps etc.)</li>
              <li>Schools and educational facilities</li>
              <li>Renewable energy solution providers etc.</li>
            </ul>
         `,
        },
        {
          active: false,
          title: "3. How do customers access the solution on Imperium?",
          details: `
          Customers can access the solution through the following:
           <p>
           <b>Outright payment:</b> Customers have the option to pay the full amount of any solution using the available payment gateways.
           </p>
            <p>
              <b>Lease to own:</b> This offering enables customers to spread the payment over a period as high as 24months.
           </p>
            <p>
           <b>Power as a Service (PaaS)*:</b> This is an alternative to the lease to own offering, where the customers pay for constant electricity supply at an agreed fee without owning the asset. The subscriber is charged a flat monthly fee. (*terms and conditions apply.)
           </p>
        `,
        },
        {
          active: false,
          title: "4. What if I don’t know how much energy I use?",
          details: `
          You can immediately calculate your energy usage with the energy calculator by clicking <a href="https://imperiumng.com/energy-calculator">https://imperiumng.com/energy-calculator</a> . Also, you can request for a quote that details the cost, capacity, and appliances you can use on each system. Kindly contact us today at info@imperiumng.com.
 
        `,
        },
        {
          active: false,
          title: "5. I have plans for expansion. Can I plan for it now?",
          details: `
          We frequently consider future growth plans when designing your system. The choice of inverter can be influenced by near-term expectations about power usage and in some cases, it may be best to include excess capacity now and simply add more solar panels later. We are available to discuss your plans for energy usage and explore a variety of options to meet your needs now and in the future. 
        `,
        },
        {
          active: false,
          title:
            "6.Will my roof be able to bear the load of the solar PV system?",
          details: `
          A structural analysis is done by our team to determine if your roof’s load bearing capacity is sufficient to take the load of the solar PV modules. Concrete roofs have no problems at all in handling the distributed load nature of solar PV modules, however T-slabs, asbestos & truss-based roofs have to be analyzed first before they are used for installation.
 
        `,
        },
        {
          active: false,
          title:
            "7. Are there any running costs associated with renewable solution?",
          details: `
          Once your system is installed, electricity production is completely free. There are no ongoing running costs. However, the panels should be cleaned at least once every year. The cost for these is very small.
 
        `,
        },
        {
          active: false,
          title: "8. Are there more benefits from using solar panels?",
          details: `
          Yes. Solar solutions globally are proven to be more cost efficient than traditional forms of power generation (grid and carbon powered generators) in the medium and long term. Also, the less dependent we are on petrol and diesel-powered generators the better our environment will be. Solar solutions contribute greatly to the environment by reducing or eliminating carbon emissions otherwise produced by other sources of energy. 
        `,
        },
        {
          active: false,
          title: "9. How long do the batteries last for?",
          details: `
          Battery life may vary according to the solution provided and subject to usage. Information on each battery life is provided in the product details. 
        `,
        },

        {
          active: false,
          title: "10. How long does the Inverter last for?",
          details: `
          Inverter life may vary according to the solution provided and subject usage. Information on each inverter life is provided in the product details. 
        `,
        },
        {
          active: false,
          title:
            "11. What’s the difference between my electricity usage and my electricity load?",
          details: `
          Electricity usage is the number of units of electricity that you use each day and is measured in kilowatt-hours (Kwh). Electricity load is the amount of energy that is required to run all the electrical appliances you use simultaneously. 
        `,
        },
        {
          active: false,
          title: "12. What happens when you default on your obligations?",
          details: `
          You will be contacted and reminded of past due obligation on the first event of a default. Any subsequent default would result in a recovery of the solution. 
        `,
        },
        {
          active: false,
          title: "13. Is there any other cost apart from the solution cost?",
          details: `
          Yes, the cost of installation will be paid upfront by the user. 
        `,
        },
        {
          active: false,
          title: "14. What is the Payment Due date?",
          details: `
          The payment date is the last day of every month. 
        `,
        },
        {
          active: false,
          title: "15. What happens if my solution is faulty?",
          details: `
          Customers are eligible to after sales services. While aftersales services are free during the warranty period, the customer will have to pay some fees after warranty period. 
        `,
        },
        {
          active: false,
          title: "16. What if I have any complaint?",
          details: `
        If you have any complaint, kindly contact us the Imperium team via email info@imperiumng.com or call any of these numbers 08164133937, 08135473439.
        `,
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.calHeading {
  display: flex;
  h2 {
    font-weight: bold;
    font-size: 32.256px;
    color: #2b2b2b;
    display: inline-block;
    margin-bottom: 0px;
  }
}
.basedClass {
  background: #e0ecce;
  border-radius: 19px;
  display: inline-block;
  div {
    display: inline-block;
    font-weight: 500;
    font-size: 14.9652px;
    color: #255e13;
    padding: 7px 32px;
    line-height: 51px;
    cursor: pointer;
    &.active {
      background-color: #219653;
      border-radius: 19px;
      color: white;
    }
  }
}
.appliance {
  color: black;
  background: #0000000a;
  padding: 16px;
  margin-bottom: 19px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  span {
    font-size: 31px;
    padding: 1px 7px;
    cursor: pointer;
  }
  .name {
    margin: 0;
    font-weight: bold;
  }
  .descr {
    margin: 0;
    font-size: 14px;
    opacity: 0.6;
  }
}
.bg-prp {
  background: #f5fcf2;
}
@media only screen and (max-width: 768px) {
  .headerimg {
    width: 72%;
    padding-top: 60px;
  }
  .basedClass {
    margin-top: 20px;
  }
  .formView {
    margin-top: 0px !important;
    background: transparent;
  }
  .maintenn {
    margin-top: 45px;
  }
  .yourCal {
    padding: 0px;
    border: none;
  }
}
.evaluation .evaluateMedia p {
  font-weight: 300;
  font-size: 18px;
  color: #202020;
}
.tabs {
  width: 100%;
  overflow: hidden;
  border: 1px solid #219653;
  box-sizing: border-box;
  border-radius: 13px;
  padding: 25px;
  cursor: pointer;
  margin-bottom: 20px;

  .tab-heading {
    color: #202020;
    font-weight: bold;
    font-size: 18px;
    img {
      float: right;
      transform: rotateZ(-90deg);
      &.active {
        transform: rotateZ(0deg);
      }
    }
  }
  .tab-content {
    padding: 1em 0em;
    color: #6e6e6e;
    background: #fff;
    -webkit-transition: all 0.35s;
    transition: all 0.35s;
    font-size: 15px;
    line-height: 1.8;
  }
}
</style>