<template>
  <dl class="accordion box" role="presentation">
    <accordion-item
      v-for="(item, index) in content"
      :multiple="multiple"
      :item="item"
      :index="index"
      :groupId="groupId"
      :key="index"
    >
    </accordion-item>
  </dl>
</template>
<script>
import AccordionItem from "@/components/Faqs/AccordionItem";

export default {
  components: { AccordionItem },
  data() {
    return {
      groupId: null,
    };
  },
  props: {
    content: {
      type: Array,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.groupId = this.$el.id;
  },
};
</script>